<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <AppLogo />
        <!-- <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Entrer le code de validation 🔒
          </b-card-title>
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Code"
                label-for="code"
              >
                <b-form-input
                  id="code"
                  v-model="userCode"
                  :state="$v.userCode.$error ? false : null"
                  name="code"
                />
                <div v-if="$v.userCode.$error">
                  <small
                    v-if="$v.userCode.required.$invalid"
                    class="text-danger"
                  >Ce champ est requis</small>
                </div>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="codeValidation"
              >
                Confirmer
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link href="/login">
              <feather-icon icon="ChevronLeftIcon" /> Retour à la connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import router from 'vue-router'
import AppLogo from '@/components/AppLogo.vue'

export default {
  setup: () => ({ $v: useVuelidate({ $lazy: true }) }),
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    AppLogo,
  },
  data() {
    return {
      userCode: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    // console.log(this.$route)
  },
  validations() {
    return {
      userCode: { required },
    }
  },
  methods: {
    ...mapActions('auth', {
      action_codeValidation: 'codeValidation',
    }),
    async validationForm() {
      if (this.isLoading) return
      const isFormCorrect = await this.$v.$validate()
      if (!isFormCorrect) return
    },
    async codeValidation() {
      const emailUser = this.$route.query.email
      const params = { email: emailUser, code: this.userCode }
      this.action_codeValidation(params)
        .then(res => {
          // console.log('response when code: ', res)
          if (res) {
            this.$router.push({ name: 'auth-reset-password-v2' })
          }
        })
        .catch(err => {
          // console.log('err', err)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Mauvais code. Veuillez revoir le code qui vous ait été envoyé',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
